import { useState, useCallback } from 'react';
import { type Link } from '@/common/types';
import type { PopupPartner } from '@/components/Popup';
import { linkablePartners } from './constants';

export const useLinks = (initialLinks: Link[]) => {
  const [links, setLinks] = useState(initialLinks);
  const [linkRegisteredAction, setLinkRegisteredAction] =
    useState<(code: string) => void>();
  const [unlinkRegisteredAction, setUnlinkRegisteredAction] =
    useState<(linkId: string, partner: PopupPartner) => void>();

  const getIagLinks = useCallback(
    () => links?.filter(link => linkablePartners.includes(link.code)),
    [links],
  );

  const getExternalLinks = useCallback(
    () => links?.filter(link => !linkablePartners.includes(link.code)),
    [links],
  );

  const removeLink = useCallback(
    (linkId: string) => {
      const index = links?.findIndex((link: Link) => link.linkId === linkId);
      if (index === -1) return;

      const newLinks: Link[] = [...links];
      delete newLinks[index].linkId;
      setLinks(newLinks);
    },
    [links],
  );

  const updateLink = useCallback(
    (code: string, options: Partial<Link>) => {
      const index = links?.findIndex((link: Link) => link.code === code);
      if (index === -1) return;

      const newLinks: Link[] = [...links];
      newLinks[index] = {
        ...links[index],
        ...options,
      };
      setLinks(newLinks);
    },
    [links],
  );

  return {
    links,
    setLinks,
    removeLink,
    updateLink,
    getIagLinks,
    getExternalLinks,
    onLinkCallback: linkRegisteredAction,
    setLinkRegisteredAction,
    onUnlinkCallback: unlinkRegisteredAction,
    setUnlinkRegisteredAction,
  };
};
